import { default as indexyAkgqUwQETMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/auth/index.vue?macro=true";
import { default as index6u0XhRlR8CMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as defectActivitiesjLff021heDMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/defects/[id]/defectActivities.vue?macro=true";
import { default as defectBaseData9bd1l9uAPRMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/defects/[id]/defectBaseData.vue?macro=true";
import { default as _91id_938X6ujmIZENMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/defects/[id].vue?macro=true";
import { default as indexlfDjqXOtJiMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/index.vue?macro=true";
import { default as reportActivities7UP1Cf4SNzMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/reports/[id]/reportActivities.vue?macro=true";
import { default as reportBaseDataKXcMwSKlrLMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/reports/[id]/reportBaseData.vue?macro=true";
import { default as reportDefectsWKW0BLjvMDMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/reports/[id]/reportDefects.vue?macro=true";
import { default as _91id_931vXNZVuIxGMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/reports/[id].vue?macro=true";
import { default as language1QWImZuM3SMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/language.vue?macro=true";
import { default as _91id_93OTKyyyuioRMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as indexcmLIoLsC6LMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as organisationBaseDataK221ZFdpgnMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_93h7KKUrWONkMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/organisations/[id].vue?macro=true";
import { default as indexeyJcL0DttFMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/organisations/index.vue?macro=true";
import { default as roles2OkFHSVPLiMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/roles.vue?macro=true";
import { default as userActivitiesfhfA3BRqH5Meta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDataaw76B6oenhMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationsMGmcQrEGzrMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_937f976ftyv5Meta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/users/[id].vue?macro=true";
import { default as index512pvPqsaUMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/users/index.vue?macro=true";
import { default as usersiA2fPogd2RMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/users.vue?macro=true";
import { default as settingspo3MaK7bX6Meta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings.vue?macro=true";
import { default as _91_46_46_46slug_93qG9LvUF21iMeta } from "/home/forge/app.eis-s.de/releases/20241009132349/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: indexyAkgqUwQETMeta?.name ?? "auth",
    path: indexyAkgqUwQETMeta?.path ?? "/auth",
    meta: indexyAkgqUwQETMeta || {},
    alias: indexyAkgqUwQETMeta?.alias || [],
    redirect: indexyAkgqUwQETMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: index6u0XhRlR8CMeta?.name ?? "auth-social-organisation-provider",
    path: index6u0XhRlR8CMeta?.path ?? "/auth/social/:organisation()/:provider()",
    meta: index6u0XhRlR8CMeta || {},
    alias: index6u0XhRlR8CMeta?.alias || [],
    redirect: index6u0XhRlR8CMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_938X6ujmIZENMeta?.name ?? "defects-id",
    path: _91id_938X6ujmIZENMeta?.path ?? "/defects/:id()",
    children: [
  {
    name: defectActivitiesjLff021heDMeta?.name ?? "defects-id-defectActivities",
    path: defectActivitiesjLff021heDMeta?.path ?? "defectActivities",
    meta: defectActivitiesjLff021heDMeta || {},
    alias: defectActivitiesjLff021heDMeta?.alias || [],
    redirect: defectActivitiesjLff021heDMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/defects/[id]/defectActivities.vue").then(m => m.default || m)
  },
  {
    name: defectBaseData9bd1l9uAPRMeta?.name ?? "defects-id-defectBaseData",
    path: defectBaseData9bd1l9uAPRMeta?.path ?? "defectBaseData",
    meta: defectBaseData9bd1l9uAPRMeta || {},
    alias: defectBaseData9bd1l9uAPRMeta?.alias || [],
    redirect: defectBaseData9bd1l9uAPRMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/defects/[id]/defectBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_938X6ujmIZENMeta || {},
    alias: _91id_938X6ujmIZENMeta?.alias || [],
    redirect: _91id_938X6ujmIZENMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/defects/[id].vue").then(m => m.default || m)
  },
  {
    name: indexlfDjqXOtJiMeta?.name ?? "index",
    path: indexlfDjqXOtJiMeta?.path ?? "/",
    meta: indexlfDjqXOtJiMeta || {},
    alias: indexlfDjqXOtJiMeta?.alias || [],
    redirect: indexlfDjqXOtJiMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_931vXNZVuIxGMeta?.name ?? "reports-id",
    path: _91id_931vXNZVuIxGMeta?.path ?? "/reports/:id()",
    children: [
  {
    name: reportActivities7UP1Cf4SNzMeta?.name ?? "reports-id-reportActivities",
    path: reportActivities7UP1Cf4SNzMeta?.path ?? "reportActivities",
    meta: reportActivities7UP1Cf4SNzMeta || {},
    alias: reportActivities7UP1Cf4SNzMeta?.alias || [],
    redirect: reportActivities7UP1Cf4SNzMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/reports/[id]/reportActivities.vue").then(m => m.default || m)
  },
  {
    name: reportBaseDataKXcMwSKlrLMeta?.name ?? "reports-id-reportBaseData",
    path: reportBaseDataKXcMwSKlrLMeta?.path ?? "reportBaseData",
    meta: reportBaseDataKXcMwSKlrLMeta || {},
    alias: reportBaseDataKXcMwSKlrLMeta?.alias || [],
    redirect: reportBaseDataKXcMwSKlrLMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/reports/[id]/reportBaseData.vue").then(m => m.default || m)
  },
  {
    name: reportDefectsWKW0BLjvMDMeta?.name ?? "reports-id-reportDefects",
    path: reportDefectsWKW0BLjvMDMeta?.path ?? "reportDefects",
    meta: reportDefectsWKW0BLjvMDMeta || {},
    alias: reportDefectsWKW0BLjvMDMeta?.alias || [],
    redirect: reportDefectsWKW0BLjvMDMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/reports/[id]/reportDefects.vue").then(m => m.default || m)
  }
],
    meta: _91id_931vXNZVuIxGMeta || {},
    alias: _91id_931vXNZVuIxGMeta?.alias || [],
    redirect: _91id_931vXNZVuIxGMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/reports/[id].vue").then(m => m.default || m)
  },
  {
    name: settingspo3MaK7bX6Meta?.name ?? "settings",
    path: settingspo3MaK7bX6Meta?.path ?? "/settings",
    children: [
  {
    name: language1QWImZuM3SMeta?.name ?? "settings-language",
    path: language1QWImZuM3SMeta?.path ?? "language",
    meta: language1QWImZuM3SMeta || {},
    alias: language1QWImZuM3SMeta?.alias || [],
    redirect: language1QWImZuM3SMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OTKyyyuioRMeta?.name ?? "settings-loginProviders-id",
    path: _91id_93OTKyyyuioRMeta?.path ?? "loginProviders/:id()",
    meta: _91id_93OTKyyyuioRMeta || {},
    alias: _91id_93OTKyyyuioRMeta?.alias || [],
    redirect: _91id_93OTKyyyuioRMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexcmLIoLsC6LMeta?.name ?? "settings-loginProviders",
    path: indexcmLIoLsC6LMeta?.path ?? "loginProviders",
    meta: indexcmLIoLsC6LMeta || {},
    alias: indexcmLIoLsC6LMeta?.alias || [],
    redirect: indexcmLIoLsC6LMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93h7KKUrWONkMeta?.name ?? "settings-organisations-id",
    path: _91id_93h7KKUrWONkMeta?.path ?? "organisations/:id()",
    children: [
  {
    name: organisationBaseDataK221ZFdpgnMeta?.name ?? "settings-organisations-id-organisationBaseData",
    path: organisationBaseDataK221ZFdpgnMeta?.path ?? "organisationBaseData",
    meta: organisationBaseDataK221ZFdpgnMeta || {},
    alias: organisationBaseDataK221ZFdpgnMeta?.alias || [],
    redirect: organisationBaseDataK221ZFdpgnMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93h7KKUrWONkMeta || {},
    alias: _91id_93h7KKUrWONkMeta?.alias || [],
    redirect: _91id_93h7KKUrWONkMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/organisations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexeyJcL0DttFMeta?.name ?? "settings-organisations",
    path: indexeyJcL0DttFMeta?.path ?? "organisations",
    meta: indexeyJcL0DttFMeta || {},
    alias: indexeyJcL0DttFMeta?.alias || [],
    redirect: indexeyJcL0DttFMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: roles2OkFHSVPLiMeta?.name ?? "settings-roles",
    path: roles2OkFHSVPLiMeta?.path ?? "roles",
    meta: roles2OkFHSVPLiMeta || {},
    alias: roles2OkFHSVPLiMeta?.alias || [],
    redirect: roles2OkFHSVPLiMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/roles.vue").then(m => m.default || m)
  },
  {
    path: usersiA2fPogd2RMeta?.path ?? "users",
    children: [
  {
    name: _91id_937f976ftyv5Meta?.name ?? "settings-users-id",
    path: _91id_937f976ftyv5Meta?.path ?? ":id()",
    children: [
  {
    name: userActivitiesfhfA3BRqH5Meta?.name ?? "settings-users-id-userActivities",
    path: userActivitiesfhfA3BRqH5Meta?.path ?? "userActivities",
    meta: userActivitiesfhfA3BRqH5Meta || {},
    alias: userActivitiesfhfA3BRqH5Meta?.alias || [],
    redirect: userActivitiesfhfA3BRqH5Meta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: userBaseDataaw76B6oenhMeta?.name ?? "settings-users-id-userBaseData",
    path: userBaseDataaw76B6oenhMeta?.path ?? "userBaseData",
    meta: userBaseDataaw76B6oenhMeta || {},
    alias: userBaseDataaw76B6oenhMeta?.alias || [],
    redirect: userBaseDataaw76B6oenhMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: userCommunicationsMGmcQrEGzrMeta?.name ?? "settings-users-id-userCommunications",
    path: userCommunicationsMGmcQrEGzrMeta?.path ?? "userCommunications",
    meta: userCommunicationsMGmcQrEGzrMeta || {},
    alias: userCommunicationsMGmcQrEGzrMeta?.alias || [],
    redirect: userCommunicationsMGmcQrEGzrMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
],
    meta: _91id_937f976ftyv5Meta || {},
    alias: _91id_937f976ftyv5Meta?.alias || [],
    redirect: _91id_937f976ftyv5Meta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/users/[id].vue").then(m => m.default || m)
  },
  {
    name: index512pvPqsaUMeta?.name ?? "settings-users",
    path: index512pvPqsaUMeta?.path ?? "",
    meta: index512pvPqsaUMeta || {},
    alias: index512pvPqsaUMeta?.alias || [],
    redirect: index512pvPqsaUMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/users/index.vue").then(m => m.default || m)
  }
],
    name: usersiA2fPogd2RMeta?.name ?? undefined,
    meta: usersiA2fPogd2RMeta || {},
    alias: usersiA2fPogd2RMeta?.alias || [],
    redirect: usersiA2fPogd2RMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings/users.vue").then(m => m.default || m)
  }
],
    meta: settingspo3MaK7bX6Meta || {},
    alias: settingspo3MaK7bX6Meta?.alias || [],
    redirect: settingspo3MaK7bX6Meta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93qG9LvUF21iMeta?.name ?? "wiki-slug",
    path: _91_46_46_46slug_93qG9LvUF21iMeta?.path ?? "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93qG9LvUF21iMeta || {},
    alias: _91_46_46_46slug_93qG9LvUF21iMeta?.alias || [],
    redirect: _91_46_46_46slug_93qG9LvUF21iMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241009132349/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]